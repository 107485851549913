import React from 'react'
import { Link } from 'gatsby'
import shape1 from '../../assets/images/about/about-shape1.png'
import starIcon from '../../assets/images/star-icon.png'
import siteInternet from '../../assets/images/home/site-internet.png'

const ModSite = () => {
    return (
        <div className="about-area ptb-70">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-img">
                            <img src={siteInternet} alt="banner" />
                            <div className="shape">
                                <img src={shape1} alt="banner" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <span className="sub-title">
                                    <img src={starIcon} alt="" /> 
                                    Création ou refonte de site ?
                                </span>
                                <h2>Site internet profesionnel et personnalisé</h2>
                                <p>Aujourd’hui, les sites internes font <span className='bold'>partie intégrante de nos modes de vie</span>, et nous permettent de faire une pluralité de choses :</p>           
                                <p id="list-custom">
                                    <i className="flaticon-tick"></i> Acheter des produits/services <br></br>
                                    <i className="flaticon-tick"></i> Chercher de l'informations<br></br>
                                    <i className="flaticon-tick"></i> Effectuer des réservations<br></br>
                                    <i className="flaticon-tick"></i> Stocker des souvenirs <br></br>
                                    <i className="flaticon-tick"></i> Jouer à des jeux<br></br>
                                    <i className="flaticon-tick"></i> Discuter avec des amis depuis l'autre bout du monde
                                </p>
                                <p>Les sites internets offrent des millions d’opportunités aux utilisateurs. Seekreet vous propose de créer un site fonctionnel pour mettre en avant vos produits et services.</p>
                                <Link to="/contact" className="default-btn">
                                    <i className="flaticon-right"></i> 
                                    Demandez un rendez-vous <span></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModSite