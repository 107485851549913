import React from 'react'
import boutique from '../../assets/images/icones/boutique.gif'
import vitrine from '../../assets/images/icones/vitrine.gif'
import blog from '../../assets/images/icones/blog.gif'
import surMesure from '../../assets/images/icones/sur-mesure.gif'
import miniSite from '../../assets/images/icones/mini-site.gif'
import communaute from '../../assets/images/icones/centralisation.gif'
import shape3 from '../../assets/images/services/service-shape3.png'

const TypeDeSite = () => {
    return (
        <div className="featured-services-area pt-50 pb-50">
            <div className="container">
                <div className="upcoming-bootcamps-content bottom">
                    <h2 className="pb-50">Choisissez le site qui vous convient !</h2>
                    <p className='center'>Vouloir un site internet est la première étape, mais <span className='bold'>comment choisir la solution</span> ce qui correspond le mieux à vos besoins ?<br></br>
                    <span className='bold'>Seekreet</span> vous <span className='bold'>préconise</span> le type de site le plus adapté et <span className='bold'>vous accompagne</span> dans le développement de votre projet.<br></br></p>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-featured-services-item">
                            <div className="icon">
                                <img src={boutique} alt="features" />
                            </div>
                            <h3>
                                La boutique en ligne
                            </h3>

                            <div className="shape">
                                <img src={shape3} alt="features" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-featured-services-item">
                            <div className="icon">
                                <img src={vitrine} alt="features" />
                            </div>

                            <h3>
                                Le site vitrine
                            </h3>

                            <div className="shape">
                                <img src={shape3} alt="features" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                        <div className="single-featured-services-item">
                            <div className="icon">
                                <img src={blog} alt="features" />
                            </div>

                            <h3>
                                Le Blog
                            </h3>

                            <div className="shape">
                                <img src={shape3} alt="features" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-featured-services-item">
                            <div className="icon">
                                <img src={miniSite} alt="features" />
                            </div>
                            <h3>
                                La Landing page
                            </h3>

                            <div className="shape">
                                <img src={shape3} alt="features" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-featured-services-item">
                            <div className="icon">
                                <img src={communaute} alt="features" />
                            </div>

                            <h3>
                                Le site communautaire
                            </h3>

                            <div className="shape">
                                <img src={shape3} alt="features" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                        <div className="single-featured-services-item">
                            <div className="icon">
                                <img src={surMesure} alt="features" />
                            </div>

                            <h3>
                                Le site sur mesure 
                            </h3>

                            <div className="shape">
                                <img src={shape3} alt="features" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TypeDeSite