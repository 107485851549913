import React from 'react'
import img1 from '../../assets/images/banner-img3.png'
import starIcon from '../../assets/images/star-icon.png'

const WhyWispeek = () => {
    return (
        <div className="overview-area ptb-70">
            <div className="container">
                <div className="overview-box">
                    <div className="overview-content">
                        <div className="content">
                            <div>
                                <div className="about-content">
                                    <div className="content">
                                        <span className="sub-title">
                                            <img src={starIcon} alt="banner" /> 
                                            Pourquoi nous ?
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <h2>Pourquoi choisir Seekreet ? </h2>
                            <h3>Une entreprise française à taille humaine</h3>    
                            <ul className="features-list-custom">
                                <li><span><i className='bx bx-check'></i> Accompagnement tout au long du projet</span></li>
                                <li><span><i className='bx bx-check'></i> Écoute de vos besoins</span></li>
                                <li><span><i className='bx bx-check'></i> Gain de temps</span></li>
                                <li><span><i className='bx bx-check'></i> Savoir-faire</span></li>
                                <li><span><i className='bx bx-check'></i> Offre complète</span></li>
                                <li><span><i className='bx bx-check'></i> Création de site personnalisé</span></li>
                                <li><span><i className='bx bx-check'></i> Formation aux outils</span></li>
                                <li><span><i className='bx bx-check'></i> Hébergement sécurisé de vos données</span></li>
                            </ul>
                        </div>
                    </div>

                    <div className="overview-image">
                        <div className="image">
                            <img src={img1} alt="features" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhyWispeek