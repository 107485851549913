import React from 'react'
import responsive from '../../assets/images/responsive-design.png'
import StarIcon from '../../assets/images/star-icon.png'
import serviceShape4 from '../../assets/images/services/service-shape4.png'

const ResponsiveDesign = () => {
    return (
        <section className="overview-area pt-30 pb-70 bg-d6f2e6">
            <div className="container">
                <div className="overview-box" style={{marginBottom:10}}>
                    <div className="overview-image ptb-30">
                        <div className="image">
                            <img src={responsive} alt="about" />
                        </div>
                    </div>

                    <div className="overview-content">
                        <div className="content h2-top">
                            <h2>Le responsive design</h2>
                            <div className="our-mission-content">
                                <div className="content">
                                    <span className="sub-title">
                                        <img src={StarIcon} alt="" /> 
                                        KESAKO ?
                                    </span>
                                </div>
                            </div>
                            <p>Signifie en français: Design réactif/adapté/réceptif.</p>
                            <p>Ce type de design permet d’adapter la mise en page d'un site afin que le contenu s'adapte à l'écran sur lequel il est consulté (smartphone, tablette, ordinateur de bureau, TV…).</p>
                            <div className="our-mission-content">
                                <div className="content">
                                    <span className="sub-title">
                                        <img src={StarIcon} alt="" /> 
                                        Pourquoi le responsive design ?
                                    </span>
                                </div>
                            </div>
                            <p>Aujourd’hui, avec la puissance des smartphone et la portabilité du réseau 4G (réseau 5G en déploiement), l’information est accessible à tous peu importe où vous vous trouvez.</p>
                        </div>
                    </div>
                    <div className="our-mission-content">
                        <div className="content">
                            <span className="sub-title">
                                <img src={StarIcon} alt="icone" /> 
                                    Quelques chiffres :
                            </span>
                        </div>
                    </div>
                </div>
                <div className="row" id="capteurs">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <h3>
                            70%
                            </h3>
                            <p className='pb-30'>Jusqu’à 70% du trafic Web provient d’appareils mobiles.<br></br></p>
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <h3>
                            3 heures et 22 minutes
                            </h3>
                            <p>Moyenne (dans le monde), du temps passé par jour sur internet depuis un mobile.</p>
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box-item ">
                            <h3>
                            5,19 milliards
                            </h3>
                            <p className='pb-30'>Nombre d’utilisateurs de mobile dans le monde (en Janvier 2020).</p>
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                        </div>
                    </div> 
                    <h3 className="ptb-50 center">Il est donc primordiale d’avoir un site adapté au mobile.<br></br>Seekreet vous propose la création de site internet en mode responsive design.</h3>
                </div>
            </div>
        </section>
    )
}

export default ResponsiveDesign