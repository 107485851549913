import React from 'react'
import siteInternet from '../../assets/images/site-internet.png'

const ContentSite = () => {
    return (
        <section className="overview-area pt-70 pb-30">
            <div className="container">
                <div className="overview-box">
                    <div className="overview-content">
                        <div className="content h2-top">
                            <h2>Pourquoi créer un site internet pour mon entreprise ?</h2>
                            <p>Nombreux sont les avantages d’avoir un site internet. Que ce soit à titre personnel, professionnel ou commercial, avoir <span className='bold'>un site internet vous permet</span> :</p>
                            <ul className="features-list">
                                <li><span><i className='bx bx-check'></i> Être visible sur Internet</span></li>
                                <li><span><i className='bx bx-check'></i> Booster votre activité</span></li>
                                <li><span><i className='bx bx-check'></i> Modifier vos offres en 3 clics</span></li>
                                <li><span><i className='bx bx-check'></i> Augmenter votre notoriété</span></li>
                                <li><span><i className='bx bx-check'></i> Assoir la crédibilité de votre entreprise</span></li>
                                <li><span><i className='bx bx-check'></i> Offrir un support à vos client (SAV / Avis)</span></li>
                                <li><span><i className='bx bx-check'></i> Automatiser vos tâches (Gain de temps)</span></li>
                                <li><span><i className='bx bx-check'></i> Être contacter et générer du lead</span></li>  
                            </ul>
                        </div>
                    </div>

                    <div className="overview-image">
                        <div className="image">
                            <img src={siteInternet} alt="vecteur utilisateurs d'internet" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContentSite